import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClapButton from 'react-clap-button';
import PetsIcon from '@mui/icons-material/Pets';

function RateArticle(props) {
  const {
    articleBaseRating,
    userRating,
    crupdateRating,
    disabled,
  } = props;

  const initialTotalCount = articleBaseRating + userRating;

  const [userCount, setUserCount] = useState(userRating);
  const [totalCount, setTotalCount] = useState(initialTotalCount);

  const onCountChange = async ({ count }) => {
    try {
      const { rating } = await crupdateRating(count);
      setUserCount(rating);
      setTotalCount(rating + articleBaseRating);
    } catch (err) {
      console.error('crupdateRating failed');
    }
  };

  return (
    disabled
      ? (<div>Login to Rate</div>)
      : (
        <ClapButton
          count={userCount}
          countTotal={totalCount}
          maxCount={50}
          isClicked={false}
          onCountChange={onCountChange}
          iconComponent={<PetsIcon {...props} size={38} />}
        />
      )
  );
}

RateArticle.propTypes = {
  crupdateRating: PropTypes.func.isRequired,
  articleBaseRating: PropTypes.number.isRequired,
  userRating: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default RateArticle;
