import React from 'react';
import { Router } from '@reach/router'; // eslint-disable-line import/no-extraneous-dependencies

import Profile from '../templates/Profile';
import Read from '../templates/Read';
import Home from '../templates/Home';

function ClientRoutes() {
  return (
    <Router basepath="/">
      <Home path="/" />
      <Read path="/:username/:slug" />
      <Profile path="/:username" />
    </Router>
  );
}

export default ClientRoutes;
