import { css } from '@emotion/react';

const styles = (props) => {
  const {
    theme: { colorBackground },
  } = props;

  return css`
    background: ${colorBackground};
  `;
};

export default styles;
