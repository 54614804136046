import React from 'react';
import PropTypes from 'prop-types';
import { cosmicpuma } from '@nonsequitur/component-library';

const { Button } = cosmicpuma;

function BottomBar(props) {
  const { actions, classes, className } = props;

  return (
    <div className={className}>
      {actions.map(({ children, ...rest }) => (
        <Button
          {...rest}
          className={classes.actions}
          key={children}
        >
          {children}
        </Button>
      ))}
    </div>
  );
}

BottomBar.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.shape({
    actions: PropTypes.string,
  }),
  className: PropTypes.string,
};

BottomBar.defaultProps = {
  classes: {
    actions: 'bottombar-actions',
  },
  className: undefined,
};

export default BottomBar;
