import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { DateTime as dt } from 'luxon';
import { cosmicpuma } from '@nonsequitur/component-library';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Layout from '../../components/Layout';
import { RestClientContext } from '../../components/contexts/RestClient';
import Hero from './Hero';
import getTrendingArticles from '../../services/get-trending-articles';
import { makeArticlePath } from '../../utils';
import { useMediaQuery, useAsyncFn } from '../../utils/hooks';

const { Card, Button } = cosmicpuma;

function ArticleTitle({ path, title }) {
  const handleNavigation = (event) => {
    event.preventDefault();
    navigate(path);
  };
  return (
    <Button
      color="secondary"
      variant="text"
      onClick={handleNavigation}
      className="card-article-title"
    >
      {title}

    </Button>
  );
}

ArticleTitle.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

function Home() {
  const region = process.env.GATSBY_USER_POOL_REGION;
  const UserPoolId = process.env.GATSBY_USER_POOL_ID;

  const { breakpointS } = useTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpointS})`);

  const { articlesClient } = useContext(RestClientContext);

  const [{
    isLoading: isLoadingTrendingArticles,
    isError: isErrorTrendingArticles,
    data: trendingArticles,
  }] = useAsyncFn(getTrendingArticles, [articlesClient, { UserPoolId, region }]);

  const handleOnClickUsername = (username) => (event) => {
    event.preventDefault();
    navigate(`/${username}`);
  };

  return (
    <div>
      <h1>Trending Stories Today</h1>
      <p>Relax and stay a while.</p>
      <Snackbar open={isErrorTrendingArticles} autoHideDuration={3000}>
        <Alert severity="error" variant="filled" elevation={6}>
          Error loading articles.
        </Alert>
      </Snackbar>
      {isLoadingTrendingArticles && (
      <div>
        <CircularProgress />
      </div>
      )}
      {trendingArticles && trendingArticles.map((article) => {
        const path = makeArticlePath(article);
        const formattedPublicationDate = dt.fromISO(article.publicationDate).toFormat('LLL dd, yyyy');
        return (
          <Card
            key={article.id}
            author={article.username}
            authorComponentProps={{
              onClick: handleOnClickUsername(article.username),
              variant: 'text',
              color: 'none',
            }}
            avatarComponentProps={{
              src: 'http://i.stack.imgur.com/Dj7eP.jpg',
              round: true,
            }}
            TitleComponent={ArticleTitle}
            titleComponentProps={{ path, title: article.title }}
            title={article.title}
            description={article.description}
            category={article.category}
            tags={article.tags}
            rating={4}
            views={2}
            publishDate={formattedPublicationDate}
            isSmallScreen={isSmallScreen}
            css={css`
              margin-bottom: 20px;
            `}
          />
        );
      })}
    </div>
  );
}

function Wrapper(props) {
  return (
    <Layout>
      <Hero />
      <Grid container spacing={3}>
        <Grid item xs={false} sm={1} md={3} />
        <Grid item xs={12} sm={10} md={6}>
          <Home {...props} />
        </Grid>
        <Grid item xs={false} sm={1} md={6} />
      </Grid>
    </Layout>
  );
}

export default Wrapper;
