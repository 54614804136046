import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import MUITextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import { cosmicpuma } from '@nonsequitur/component-library';

import { RestClientContext } from '../contexts/RestClient';

const { Button, DropDown, TextField } = cosmicpuma;

function PublishModal(props) {
  const {
    isFullScreen,
    isModalOpen,
    setIsModalOpen,
    article,
    onChangeArticleDetail,
    categoryOptions,
    tagOptions,
    text,
    className,
    classes,
  } = props;

  const { description, category, tags } = article;

  const handleCloseModal = (event) => {
    event.preventDefault();
    setIsModalOpen(false);
  };

  const handleChangeArticleDetail = (key) => (event) => {
    event.preventDefault();
    const { value } = event.target;
    onChangeArticleDetail(key)(value);
  };

  const handleChangeTags = (event, value) => {
    event.preventDefault();
    const tags = value.map((tag) => tag.value);
    onChangeArticleDetail('tags')(tags);
  };

  const { articlesClient } = useContext(RestClientContext);

  const handlePublish = async (event) => {
    event.preventDefault();
    try {
      await Promise.all([
        articlesClient.updateArticleTextById(article.id, text),
        articlesClient.publishArticle(article),
      ]);
      navigate('/');
    } catch (err) {
      console.error(err);
      console.error('problem publishing article');
    }
  };

  return (
    <Dialog
      fullScreen={isFullScreen}
      open={isModalOpen}
      onBackdropClick={handleCloseModal}
      onEscapeKeyDown={handleCloseModal} // some bug here
      className={className}
      classes={{
        paper: 'dialog-root',
      }}
    >
      <Title
        classes={classes}
        onClose={handleCloseModal}
      >
        Publish
      </Title>
      <Content
        classes={classes}
        description={description}
        category={category}
        tags={tags}
        categoryOptions={categoryOptions}
        tagOptions={tagOptions}
        onChangeDescription={handleChangeArticleDetail('description')}
        onChangeCategory={handleChangeArticleDetail('category')}
        onChangeTags={handleChangeTags}
      />
      <Actions
        classes={classes}
        onPublish={handlePublish}
      />
    </Dialog>
  );
}

PublishModal.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({
    title: PropTypes.string,
    titleBar: PropTypes.string,
    closeButton: PropTypes.string,
    content: PropTypes.string,
    descriptionInput: PropTypes.string,
    categoryInput: PropTypes.string,
    tagsInput: PropTypes.string,
    actionBar: PropTypes.string,
    actions: PropTypes.string,
  }),
  isFullScreen: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func.isRequired,
  article: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
  }).isRequired,
  text: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeArticleDetail: PropTypes.func.isRequired,
  categoryOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  tagOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
};

PublishModal.defaultProps = {
  className: undefined,
  isFullScreen: false,
  isModalOpen: false,
  classes: {
    titleBar: 'publish-modal-title-bar',
    title: 'publish-modal-title',
    closeButton: 'publish-modal-close-button',
    content: 'publish-modal-content',
    descriptionInput: 'publish-modal-description-input',
    categoryInput: 'publish-modal-category-input',
    tagsInput: 'publish-modal-tags-input',
    actionBar: 'publish-modal-action-bar',
    actions: 'publish-modal-actions',
  },
  categoryOptions: [],
  tagOptions: [],
};

function Title(props) {
  const { children, classes, onClose } = props;
  return (
    <DialogTitle className={classes.titleBar} disableTypography>
      <div className={classes.title}>{children}</div>
      <IconButton
        aria-label="close-publish-mod"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    titleBar: PropTypes.string,
    title: PropTypes.string,
    closeButton: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

function Content(props) {
  const {
    classes,
    description,
    category,
    tags,
    onChangeDescription,
    onChangeCategory,
    onChangeTags,
    categoryOptions,
    tagOptions,
  } = props;

  const tagsValue = tagOptions.filter((option) => tags.includes(option.value));

  return (
    <DialogContent className={classes.content}>
      <TextField
        id="article-description-input"
        name="article-description-input"
        type="text"
        placeholder="Enter a description..."
        label="Description"
        maxLength={256}
        isRequired
        showCharacterCount
        value={description}
        onChange={onChangeDescription}
        className={classes.descriptionInput}
      />
      <DropDown
        id="article-category-select"
        name="article-category-select"
        label="Category"
        placeholder="Choose a category..."
        isRequiredField
        value={category}
        onChange={onChangeCategory}
        options={categoryOptions}
        className={classes.categoryInput}
      />
      <Autocomplete
        id="article-tags-multi-select"
        multiple
        limitTags={3}
        value={tagsValue}
        onChange={onChangeTags}
        options={tagOptions}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        popupIcon={<ExpandMoreIcon />}
        renderInput={(params) => (
          <MUITextField
            {...params}
            label="Tags"
            placeholder="Choose tags..."
            variant="outlined"
            required
            className={classes.tagsInput}
          />
        )}
      />
    </DialogContent>
  );
}

Content.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string,
    descriptionInput: PropTypes.string,
    categoryInput: PropTypes.string,
    tagsInput: PropTypes.string,
  }).isRequired,
  description: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  onChangeCategory: PropTypes.func.isRequired,
  onChangeTags: PropTypes.func.isRequired,
  categoryOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  tagOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
};

Content.defaultProps = {
  categoryOptions: [],
  tagOptions: [],
};

function Actions(props) {
  const { classes, onPublish } = props;
  return (
    <DialogActions className={classes.actionBar}>
      <Button
        className={classes.actions}
        onClick={onPublish}
        color="secondary"
        variant="solid"
        size="small"
        ariaLabel="Save and Publish"
      >
        Save & Publish
      </Button>
    </DialogActions>
  );
}

Actions.propTypes = {
  classes: PropTypes.shape({
    actionBar: PropTypes.string,
    actions: PropTypes.string,
  }).isRequired,
  onPublish: PropTypes.func.isRequired,
};

export default PublishModal;
