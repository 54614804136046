import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import SignIn from '../components/SignIn';
import SignedIn from '../components/SignedIn';
import Register from '../components/Register';
import VerifyAccount from '../components/VerifyAccount';
import { AuthContext } from '../components/contexts/Auth';
import AppAuthMode from '../components/AppAuthMode';

function Login(props) {
  const { location } = props;
  const currentAppAuthMode = location?.state?.appAuthMode || AppAuthMode.REGISTER;
  const { isSignedIn } = useContext(AuthContext);

  const [localAuthState, setLocalAuthState] = useState(currentAppAuthMode);
  const [email, setEmail] = useState();

  const renderForm = (authState) => {
    switch (authState) {
      case AppAuthMode.REGISTER:
        return (
          <Register
            changeAuthMode={setLocalAuthState}
            setEmailAlreadyExists={setEmail}
          />
        );

      case AppAuthMode.SIGN_IN:
        return (
          <SignIn
            changeAuthMode={setLocalAuthState}
            setEmailNeedsVerified={setEmail}
          />
        );

      case AppAuthMode.VERIFY:
        return (
          <VerifyAccount
            changeAuthMode={setLocalAuthState}
            verifyEmail={email}
          />
        );

      default:
        console.error(
          `Invalid signInMode=${authState} Defaulting to known state SIGN_IN`,
        );
        setLocalAuthState(AppAuthMode.SIGN_IN);
        return renderForm(AppAuthMode.SIGN_IN);
    }
  };

  return (
    <Layout>
      {isSignedIn ? (
        <SignedIn />
      ) : (
        renderForm(localAuthState)
      )}
    </Layout>
  );
}

Login.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      appAuthMode: PropTypes.string,
    }),
  }),
};

Login.defaultProps = {
  location: {
    state: {
      appAuthMode: AppAuthMode.REGISTER,
    },
  },
};

export default Login;
