/* eslint-disable no-param-reassign, class-methods-use-this */
const axios = require('axios').create();

class ApiClient {
  constructor(baseOptions = {}) {
    this.init(baseOptions);
  }

  /**
   * @param baseOptions.logger [Logger] the logger to use, defaults to console
   * @param baseOptions.timeout [number] the timeout millis
   * @param baseOptions.host [string] the host to communicate with
   * @param baseOptions.authProvider [function] function that accepts Map<String, String> and
   *        returns the same. Intended to mutate outgoing request headers at runtime dynamically.
   */
  init(baseOptions) {
    this.logger = baseOptions.logger || console;
    this.timeout = baseOptions.timeout || 2500;
    this.host = baseOptions.host || 'https://api-test.cosmicpuma.com';

    if (baseOptions.authProvider) {
      axios.interceptors.request.use((options) => baseOptions.authProvider(options.headers));
    }
  }

  getOptions() {
    const options = {
      logger: this.logger,
      timeout: this.timeout,
      host: this.host,
      headers: this.headers,
    };
    return options;
  }

  /**
   * Sends the request with the default low level http client.
   * @param {string} url The url, e.g. https://api-test.cosmicpuma.com/article/articles/v1
   * @param {Object} options The request options. Ref: https://github.com/axios/axios#request-config
   * @param {Object} [options.data] The body for POST, PUT, etc
   */
  request(path, options = {}) {
    const url = new URL(path, this.host);

    options.url = options.url || url.toString();
    options.method = options.method || 'get';
    options.timeout = options.timeout || this.timeout;

    this.addHeaders(options.headers);

    const requestOptions = {
      ...options,
    };

    return axios(requestOptions);
  }

  get(url) {
    return axios.get(url);
  }

  addHeaders(headers = {}) {
    const lowercasedHeaders = Object.assign({}, ...Object.keys(headers).map((key) => ({ [key.toLowerCase()]: headers[key] })));

    const defaultHeaders = {
      accept: 'application/json',
      'content-type': 'application/json',
    };

    const allHeaders = { ...defaultHeaders, ...this.headers, ...lowercasedHeaders };

    this.headers = allHeaders;
  }
}

module.exports = ApiClient;
