import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';

import Unstyled from './SignIn';
import styles from './styles';

const SignIn = styled(Unstyled)`
  ${styles}
`;

SignIn.defaultProps = {
  ...Unstyled.defaultProps,
};

export default withTheme(SignIn);
