import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { cx, css } from '@emotion/css';
import { cosmicpuma } from '@nonsequitur/component-library';

import { useHasMounted } from '../../utils/hooks';

const { theme: { colorPrimary } } = cosmicpuma;

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    },
    ref,
  ) => (
    <span
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          cursor: pointer;
          color: ${reversed
          ? active
            ? colorPrimary
            : '#aaa'
          : active
            ? colorPrimary
            : '#ccc'};
        `,
      )}
    />
  ),
);

Button.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool.isRequired,
  reversed: PropTypes.bool.isRequired,
};

Button.defaultProps = {
  className: 'hovering-toolbar-button',
};

export const Icon = React.forwardRef(
  (
    { className, ...props },
    ref,
  ) => (
    <span
      {...props}
      ref={ref}
      className={cx(
        'material-icons',
        className,
        css`
            font-size: 18px;
            vertical-align: text-bottom;
          `,
      )}
    />
  ),
);

Icon.propTypes = {
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: 'hovering-toolbar-icon',
};

export const Menu = React.forwardRef(
  (
    { className, ...props },
    ref,
  ) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
            & > * {
              display: inline-block;
            }
            & > * + * {
              margin-left: 15px;
            }
          `,
      )}
    />
  ),
);

Menu.propTypes = {
  className: PropTypes.string,
};

Menu.defaultProps = {
  className: 'hovering-toolbar-menu',
};

export function Portal({ children }) {
  /** BUILD-TIME HACK */
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return ReactDOM.createPortal(children, document.body);
}

export const Toolbar = React.forwardRef(
  (
    { className, ...props },
    ref,
  ) => (
    <Menu
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
            position: relative;
            padding: 1px 18px 17px;
            margin: 0 -20px;
            border-bottom: 2px solid #eee;
            margin-bottom: 20px;
          `,
      )}
    />
  ),
);

Toolbar.propTypes = {
  className: PropTypes.string,
};

Toolbar.defaultProps = {
  className: 'hovering-toolbar',
};
