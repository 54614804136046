import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from '../contexts/Auth';
import AppAuthMode from '../AppAuthMode';

/**
 * React component for rendering a Registration form
 *
 * @param {function} changeAuthMode callback to update the app auth mode,
 * must accept AppAuthState enum
 * @param {function} setEmailAlreadyExists callback to provide the email
 * to the parent state when errors occur
 * @returns React component
 */
function Register({ changeAuthMode, setEmailAlreadyExists }) {
  const { signUp } = useContext(AuthContext);
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();

  const handleRegistrationError = (error) => {
    switch (error.code) {
      case 'UsernameExistsException':
        console.log(
          'Username already exists in register call, finish registering, login, or make a new user',
        );
        setEmailAlreadyExists(email);
        changeAuthMode(AppAuthMode.SIGN_IN);
        break;
      default:
        console.error(error);
    }
  };

  const handleRegisterClicked = (event) => {
    event.preventDefault();
    return signUp(email, password, { preferred_username: username })
      .then(() => setEmailAlreadyExists(email))
      .then(() => changeAuthMode(AppAuthMode.VERIFY))
      .catch(handleRegistrationError);
  };

  const handleEmailChanged = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handlePasswordChanged = (event) => {
    const password = event.target.value;
    setPassword(password);
  };

  const handleUsernameChanged = (event) => {
    const username = event.target.value;
    setUsername(username);
  };

  return (
    <div label="Register" className="registration-form">
      <form>
        <label htmlFor="email">
          <p>email</p>
          <input
            type="text"
            onChange={handleEmailChanged}
            id="email"
          />
        </label>
        <label htmlFor="username">
          <p>username</p>
          <input
            type="text"
            onChange={handleUsernameChanged}
            id="username"
          />
        </label>
        <label htmlFor="password">
          <p>password</p>
          <input
            type="password"
            onChange={handlePasswordChanged}
            id="password"
          />
        </label>
        <div>
          <button type="submit" onClick={handleRegisterClicked}>
            Register
          </button>
        </div>
      </form>
    </div>
  );
}

Register.propTypes = {
  changeAuthMode: PropTypes.func,
  setEmailAlreadyExists: PropTypes.func,
};

Register.defaultProps = {
  changeAuthMode: undefined,
  setEmailAlreadyExists: undefined,
};

export default Register;
