import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Link } from 'gatsby';
import { cosmicpuma } from '@nonsequitur/component-library';

import Burger from './Burger';
import RightNav from './RightNav';
import { loggedInActions, loggedOutActions } from './actions';
import { useMediaQuery } from '../../utils/hooks';
import { RestClientContext } from '../contexts/RestClient';

const { Button } = cosmicpuma;

function AppBar(props) {
  const {
    siteTitle,
    classes,
    className,
    isSignedIn,
    user,
    signOut,
  } = props;

  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const { breakpointS, breakpointM } = useTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpointS})`);
  const isMediumScreen = useMediaQuery(`(max-width: ${breakpointM})`);

  const { articlesClient } = useContext(RestClientContext);
  const actionsContext = { user, signOut, articlesClient };
  const actions = isSignedIn ? loggedInActions(actionsContext) : loggedOutActions(actionsContext);
  const brandText = isSmallScreen ? 'CP' : siteTitle;
  return (
    <div className={className}>
      <header className={classes.header}>
        <div className={classes.brand}>
          <Link to="/">{brandText}</Link>
        </div>
        {/* <div className={classes.search}>
          <TextField />
        </div> */}
        <nav className={classes.nav}>
          {isMediumScreen
            ? (
              <>
                <Burger
                  isOpen={isBurgerOpen}
                  handleClick={() => setIsBurgerOpen(!isBurgerOpen)}
                />
                <RightNav
                  isOpen={isBurgerOpen}
                  actions={actions}
                />
              </>
            )
            : actions.map(({ children, ...rest }) => (
              <Button
                {...rest}
                className={classes.action}
                key={children}
              >
                {children}
              </Button>
            ))}
        </nav>
      </header>
    </div>
  );
}

AppBar.propTypes = {
  isSignedIn: PropTypes.bool,
  siteTitle: PropTypes.string,
  classes: PropTypes.shape({
    header: PropTypes.string,
    brand: PropTypes.string,
    nav: PropTypes.string,
    action: PropTypes.string,
  }),
  className: PropTypes.string,
  user: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  signOut: PropTypes.func.isRequired,
};

AppBar.defaultProps = {
  siteTitle: 'Cosmic Puma',
  isSignedIn: false,
  classes: {
    header: 'appbar-header',
    brand: 'appbar-brand',
    nav: 'appbar-nav',
    action: 'appbar-actions',
  },
  className: undefined,
  user: undefined,
};

export default AppBar;
