import { css } from '@emotion/react';

const styles = (props) => {
  const {
    theme: {
      typographyHeader1 = {}, // TODO: fix this build-time hack
      colorText,
    },
  } = props;

  return css`
    input {
      color: ${colorText};
      font-family: ${typographyHeader1.fontFamily};
      font-size: ${typographyHeader1.fontSize};
      font-weight: ${typographyHeader1.fontWeight};
    }

    .MuiInput-underline:after {
      border-bottom: initial;
    }
  `;
};

export default styles;
