import { css } from '@emotion/react';

const styles = (props) => {
  const {
    theme: {
      spacingS,
      spacingM,
      spacingL,
      colorTextSecondary,
      colorPrimaryHover,
      colorBackground,
      colors: {
        pinkDarken60,
        gray50,
      },
      typographyBody3,
      typographyHeader3,
      fontSizeS,
      fontWeightMedium,
    },
  } = props;

  return css`
    .profile-article-card-container {
      background: ${colorBackground};
      padding: ${spacingM};
      border-radius: 5px;
      border: 1px solid ${pinkDarken60};
      box-shadow: hsla(0, 0, 0, .2) 0 4px 2px -2px;

      &:hover {
        border: 1px solid ${colorPrimaryHover};
      }
    }

    .profile-article-card-main {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .profile-article-card-more {
      display: flex;
      flex-direction: column;
    }

    .profile-article-card-left{
      max-width: 50%;
    }

    .profile-article-card-title {
      font-size: ${typographyHeader3.fontSize};
      text-align: left;
    }

    .profile-article-card-chips {
      margin-top: ${spacingS};

      & > a {
        margin-right: ${spacingM};
      }
    }

    .profile-article-card-pdate {
      font-size: ${typographyBody3.fontSize};
      color: ${colorTextSecondary};
    }

    .profile-article-card-right {
      max-width: 50%;
      margin-left: auto;
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .profile-article-card-stats {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-right: ${spacingL};
    }

    .profile-article-card-data-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: ${spacingL}
    }

    .profile-article-card-icon {
      fill: ${gray50};
      color: ${gray50};
      margin-right: ${spacingS};

      &:hover:not(:disabled),
      &:focus:not(:disabled),
      &:active:not(:disabled) {
        fill: ${gray50};
        color: ${gray50};
      }

      &:disabled {
        fill: ${gray50};
        color: ${gray50};
      }
    }

    .profile-article-card-numero {
      color: ${gray50};
      font-size: ${fontSizeS};
      font-weight: ${fontWeightMedium};
    }

    .profile-article-card-manage {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  `;
};

export default styles;
