// inspiration: https://dev.to/danielbayerlein/custom-react-hook-for-authentication-with-amazon-cognito-38n7
import { useState, useMemo } from 'react';
import Auth from '@aws-amplify/auth';
import CosmicPumaUser from '../../components/contexts/Auth/CosmicPumaUser';

export const defaultAuthParams = {
  options: {
    userPoolRegion: process.env.GATSBY_USER_POOL_REGION,
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_USER_POOL_CLIENT_ID,
  },
};

export const useAuth = (authParams = defaultAuthParams) => {
  const { options } = authParams;

  const [state, setState] = useState({
    user: {},
    isSignedIn: false,
  });

  const auth = useMemo(() => {
    Auth.configure(options);
    return Auth;
  }, [options]);

  const signIn = (username, password) => auth
    .signIn(username, password)
    .then((cognitoUser) => {
      const cosmicPumaUser = new CosmicPumaUser({
        email: cognitoUser.attributes.email,
        username: cognitoUser.attributes.preferred_username,
        userId: cognitoUser.attributes.sub,
        accessToken: cognitoUser.signInUserSession.accessToken.jwtToken,
      });

      setState({ user: cosmicPumaUser, isSignedIn: true });
      return cosmicPumaUser;
    });

  const signUp = (username, password, attributes) => auth.signUp({
    username,
    password,
    attributes,
  });

  const confirmSignUp = (username, code) => auth.confirmSignUp(username, code);

  const resendSignup = (username) => auth.resendSignUp(username);

  const signOut = () => auth
    .signOut()
    .then(() => setState({ user: undefined, isSignedIn: false }));

  return {
    ...state,
    signIn,
    signUp,
    confirmSignUp,
    resendSignup,
    signOut,
  };
};
