import { navigate } from 'gatsby';

import AppAuthMode from '../AppAuthMode';

const handleNavigation = (path, options) => (event) => {
  event.preventDefault();
  navigate(path, options);
};

const handleWrite = ({ articlesClient, userId }) => async (event) => {
  event.preventDefault();
  try {
    const article = await articlesClient.createArticleShell({ userId });
    navigate('/write', { state: { article } });
  } catch (err) {
    console.log(err);
    navigate('/');
  }
};

const handleLogout = (signOut) => (event) => {
  event.preventDefault();
  try {
    signOut();
    navigate('/');
  } catch (err) {
    console.log(err);
  }
};

export const loggedInActions = (ctx) => [
  {
    color: 'primary',
    variant: 'text',
    children: 'Feeling Lucky',
    onClick: handleNavigation('/about'),
  },
  {
    color: 'primary',
    variant: 'text',
    children: 'Top Rated',
    onClick: handleNavigation('/about'),
  },
  {
    color: 'primary',
    variant: 'text',
    children: 'Trending',
    onClick: handleNavigation('/about'),
  },
  {
    color: 'secondary',
    variant: 'solid',
    size: 'small',
    children: 'Write',
    onClick: handleWrite({
      articlesClient: ctx.articlesClient,
      userId: ctx.user.userId,
    }),
  },
  {
    color: 'secondary',
    variant: 'text',
    children: 'Logout',
    onClick: handleLogout(ctx.signOut),
  },
];

export const loggedOutActions = () => [
  {
    color: 'primary',
    variant: 'outlined',
    size: 'small',
    children: 'Login',
    onClick: handleNavigation('/login', {
      state: { appAuthMode: AppAuthMode.SIGN_IN },
    }),
  },
  {
    color: 'secondary',
    variant: 'solid',
    size: 'small',
    children: 'Register',
    onClick: handleNavigation('/login', {
      state: { appAuthMode: AppAuthMode.REGISTER },
    }),
  },
];
