import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import MainText from './MainText';
import LeftBar from './LeftBar';
import Layout from '../../components/Layout';
import { AuthContext } from '../../components/contexts/Auth';
import { RestClientContext } from '../../components/contexts/RestClient';
import { useAsyncFn } from '../../utils/hooks';
import RatingsClient from '../../services/RatingsClient';

function ReadTemplate(props) {
  const {
    username: authorUsername,
    slug,
  } = props;

  const cuid = slug.split('-').pop();

  const { user, isSignedIn } = useContext(AuthContext);
  const { articlesClient } = useContext(RestClientContext);
  const [
    { isLoading: isLoadingArticle, data: article },
  ] = useAsyncFn(() => articlesClient.getArticleByUsernameAndCuid(authorUsername, cuid));
  const [
    { isLoading: isLoadingRating, data: rating },
  ] = useAsyncFn(() => RatingsClient.getRating({ articleId: article?.id, userId: user?.userId }));

  const crupdateRating = (rating) => RatingsClient.crupdateRating({
    articleId: article?.id,
    userId: user?.userId,
    rating,
  });

  const mainTextProps = {
    isLoadingArticle,
    article,
    authorUsername,
  };

  const leftBarProps = {
    isSignedIn,
    isLoadingRating,
    crupdateRating,
    articleId: article?.id,
    articleBaseRating: article?.netRating || 0, // TODO: remove 0 default after updating article service to support netRating
    userId: user?.userId,
    userRating: rating?.rating || 0,
  };

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={false} sm={1} md={3}>
          <LeftBar
            {...props}
            {...leftBarProps}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={6}>
          <MainText
            {...props}
            {...mainTextProps}
          />
        </Grid>
        <Grid item xs={false} sm={1} md={6} />
      </Grid>
    </Layout>
  );
}

ReadTemplate.propTypes = {
  username: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default ReadTemplate;
