import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';

import Unstyled from './Register';
import styles from './styles';

const Register = styled(Unstyled)`
  ${styles}
`;

Register.defaultProps = {
  ...Unstyled.defaultProps,
};

export default withTheme(Register);
