const defaultArticleDetails = (userId) => ({
  userId,
  title: '',
  description: '',
  category: '',
  tags: [],
});

const articleDetailsReducer = (state, action) => {
  switch (action.type) {
    case 'ARTICLE_RESET':
      return defaultArticleDetails;
    case 'ARTICLE_UPDATE_KEY':
      return {
        ...state,
        [action.key]: action.value,
      };
    case 'ARTICLE_UPDATE_ALL':
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
};

export { articleDetailsReducer, defaultArticleDetails };
