const ApiClient = require('./ApiClient');

/**
 * HttpClient for the Ratings API.
 */
class RatingsClient extends ApiClient {
  constructor({ url }) {
    super({ url });
  }

  /**
   * Create a new article.
   * @param {String} rating.articleId - the article id
   * @param {String} rating.userId - the user id
   * @param {Number} rating.rating - the rating amount
   * @returns {Rating}
   */
  crupdateRating({
    articleId,
    userId,
    rating,
  }) {
    return super.request('/rating/ratings/v1', {
      method: 'put',
      data: {
        articleId,
        userId,
        rating,
      },
    })
      .then(({ data }) => data);
  }

  /**
   * Get an article by its id.
   * @param {String} rating.articleId - the article id
   * @param {String} rating.userId - the user id
   */
  getRating({ articleId, userId }) {
    return super.request(`/rating/ratings/v1?articleId=${articleId}&userId=${userId}`)
      .then(({ data: { objects: [rating] } }) => rating);
  }

  /**
   * Get an article by its id.
   * @param {String} rating.articleId - the article id
   */
  getRatingsByArticleId({ articleId }) {
    return super.request(`/rating/ratings/v1?articleId=${articleId}`)
      .then(({ data: { objects: ratings } }) => ratings);
  }
}

const host = 'https://api-test.cosmicpuma.com';
const client = new RatingsClient({ host });

module.exports = client;
