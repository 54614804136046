import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Avatar from 'react-avatar';
import { cosmicpuma } from '@nonsequitur/component-library';
import CircularProgress from '@mui/material/CircularProgress';

import ReadOnlyEditor from '../../components/ReadOnlyEditor';
import { formatPublicationDate } from '../../utils';

const { Chip, Button } = cosmicpuma;

function MainText(props) {
  const {
    isLoadingArticle,
    article,
    authorUsername,
    classes,
    className,
  } = props;

  const handleOnClickUsername = (event) => {
    event.preventDefault();
    navigate(`/${authorUsername}`);
  };

  return (
    <div className={className}>
      <div className={classes.container}>
        {isLoadingArticle && (
          <div>
            <CircularProgress />
          </div>
        )}
        {article && (
          <>
            <div className={classes.authorLine}>
              <Avatar
                round
                size="32"
                name={authorUsername}
                className={classes.avatar}
                src={article.avatar || 'http://i.stack.imgur.com/Dj7eP.jpg'}
              />
              <Button
                className={classes.username}
                variant="text"
                color="none"
                onClick={handleOnClickUsername}
              >
                @
                {authorUsername}
              </Button>
              <span className={classes.publicationDate}>
                {formatPublicationDate(article.publicationDate)}
              </span>
            </div>
            <h1>{article.title}</h1>
            <p className={classes.description}>{article.description}</p>
            <div className={classes.chips}>
              <Chip variant="outlined" color="secondary">
                #
                {article.category}
              </Chip>
              {article.tags.map((tag) => (
                <Chip variant="solid" color="primary" key={tag}>
                  #
                  {tag}
                </Chip>
              ))}
            </div>
            <div className={classes.text}>
              {article.text
                ? <ReadOnlyEditor text={article.text} />
                : <CircularProgress />}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

MainText.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    publicationDate: PropTypes.string,
    username: PropTypes.string,
    avatar: PropTypes.string,
    authorLine: PropTypes.string,
    chips: PropTypes.string,
    text: PropTypes.string,
    description: PropTypes.string,
  }),
  className: PropTypes.string.isRequired,
  isLoadingArticle: PropTypes.bool.isRequired,
  article: PropTypes.shape({
    avatar: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    publicationDate: PropTypes.string,
    text: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  authorUsername: PropTypes.string.isRequired,
};

MainText.defaultProps = {
  classes: {
    container: 'read-container',
    publicationDate: 'read-publication-date',
    username: 'read-username',
    avatar: 'read-avatar',
    authorLine: 'read-author-line',
    chips: 'read-chips',
    text: 'read-text',
    description: 'read-description',
  },
};

export default MainText;
