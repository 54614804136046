import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { cosmicpuma } from '@nonsequitur/component-library';

import { formatPublicationDate, makeArticlePath } from '../../utils';

const { Button, Chip, Icons: { HeartSmall, EyeSmall } } = cosmicpuma;

function ProfileArticleCard(props) {
  const {
    article,
    classes,
    className,
    isSmallScreen,
    ...rest
  } = props;

  const [shouldShowMore, setShouldShowMore] = useState(false);

  const readPath = makeArticlePath(article);

  const handleShowMore = (event) => {
    event.preventDefault();
    setShouldShowMore(!shouldShowMore);
  };

  const handleOnClickTitle = (event) => {
    event.preventDefault();
    navigate(readPath);
  };

  return (
    <div className={className} {...rest}>
      <div className={classes.container}>
        <div className={classes.main}>
          <div className={classes.left}>
            <Button
              className={classes.title}
              color="secondary"
              variant="text"
              onClick={handleOnClickTitle}
            >
              {article.title}
            </Button>
            <span className={classes.publicationDate}>
              Published:
              {' '}
              {formatPublicationDate(article.publicationDate)}
            </span>
            <Button
              className={classes.showMore}
              color="none"
              variant="text"
              size="small"
              onClick={handleShowMore}
            >
              {shouldShowMore ? 'show less...' : 'show more...'}
            </Button>
          </div>
          <div className={classes.right}>
            <div className={classes.stats}>
              <div className={classes.dataContainer}>
                <HeartSmall className={classes.icon} />
                <span className={classes.numero}>{article.rating || 0}</span>
              </div>
              <div className={classes.dataContainer}>
                <EyeSmall className={classes.icon} />
                <span className={classes.numero}>{article.views || 0}</span>
              </div>
            </div>
            <div className={classes.manage}>
              <Button
                color="none"
                variant="text"
                size="small"
              >
                Manage
              </Button>
              <Button
                color="none"
                variant="text"
                size="small"
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
        {shouldShowMore && (
          <div className={classes.more}>
            <div className={classes.chips}>
              <Chip
                color="secondary"
                variant="outlined"
                size="small"
              >
                #
                {article.category}
              </Chip>
              {article.tags && article.tags.map((tag) => (
                <Chip
                  key={tag}
                  color="primary"
                  variant="solid"
                >
                  #
                  {tag}
                </Chip>
              ))}
            </div>
            <p>{article.description}</p>
          </div>
        )}
      </div>
    </div>
  );
}

ProfileArticleCard.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    rating: PropTypes.number,
    views: PropTypes.number,
    publicationDate: PropTypes.string,
    modificationDate: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  classes: PropTypes.shape({
    container: PropTypes.string,
    main: PropTypes.string,
    more: PropTypes.string,
    showMore: PropTypes.string,
    left: PropTypes.string,
    title: PropTypes.string,
    chips: PropTypes.string,
    publicationDate: PropTypes.string,
    right: PropTypes.string,
    stats: PropTypes.string,
    dataContainer: PropTypes.string,
    icon: PropTypes.string,
    numero: PropTypes.string,
    manage: PropTypes.string,
  }),
  isSmallScreen: PropTypes.bool.isRequired,
};

ProfileArticleCard.defaultProps = {
  className: undefined,
  classes: {
    container: 'profile-article-card-container',
    main: 'profile-article-card-main',
    more: 'profile-article-card-more',
    left: 'profile-article-card-left',
    title: 'profile-article-card-title',
    chips: 'profile-article-card-chips',
    publicationDate: 'profile-article-card-pdate',
    showMore: 'profile-article-card-show-more',
    right: 'profile-article-card-right',
    stats: 'profile-article-card-stats',
    dataContainer: 'profile-article-card-data-container',
    icon: 'profile-article-card-icon',
    numero: 'profile-article-card-numero',
    manage: 'profile-article-card-manage',
  },
};

export default ProfileArticleCard;
