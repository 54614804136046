import slug from 'limax';
import { DateTime as dt } from 'luxon';

const makeArticlePath = (article) => {
  const { cuid, title, username } = article;
  const sluggedTitle = slug(title);
  const path = `/${username}/${sluggedTitle}-${cuid}`;
  return path;
};

const isBrowser = () => typeof window !== 'undefined';

const formatPublicationDate = (isoDate) => dt.fromISO(isoDate).toFormat('LLL dd, yyyy');

export { isBrowser, makeArticlePath, formatPublicationDate };
