import { css } from '@emotion/react';

const styles = (props) => {
  const {
    theme: {
      spacingS,
      spacingM,
      spacingL,
      typographyBody2,
      colorTextSecondary,
      colorBackground,
      colors: {
        pinkDarken60,
      },
    },
  } = props;

  return css`
    .read-container {
      background: ${colorBackground};
      padding: ${spacingM};
      margin-top: ${spacingM};
      border-radius: 5px;
      border: 1px solid ${pinkDarken60};
      box-shadow: hsla(0, 0, 0, .2) 0 4px 2px -2px;
    }

    .read-chips > a {
      margin-right: ${spacingM};
    }

    .read-author-line {
      height: 32px;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: ${spacingS};
      margin-top: ${spacingS};
    }

    .read-avatar {
      margin-right: ${spacingS};
    }

    .read-username {
      font-size: ${typographyBody2.fontSize};
      margin-right: ${spacingS};
    }

    .read-publication-date {
      color: ${colorTextSecondary};
    }

    .read-description {
      font-weight: bold;
      font-style: italic;
      margin-bottom: ${spacingL};
    }

    .read-text {
      margin-top: ${spacingL};
    }
  `;
};

export default styles;
