import React from 'react';
import PropTypes from 'prop-types';
import { css, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { cosmicpuma } from '@nonsequitur/component-library';

const { Button } = cosmicpuma;

const styles = (props) => {
  const {
    theme: {
      fontFamilyBrand,
      colorBackground,
      colorBackgroundSecondary,
      spacingXl,
    },
  } = props;

  return css`
    color: ${colorBackground};
    padding: ${spacingXl};

    /* Sizing */
    width: 100vw;
    height: 70vh;

    /* Flexbox stuff */
    display: flex;
    flex-direction: column;

    /* Background styles */
    background-image: url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.explicit.bing.net%2Fth%3Fid%3DOIP.XmeVGvvSRjbz4RNyANc5zwHaEK%26pid%3DApi&f=1);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .hero-brand {
      font-family: ${fontFamilyBrand};
      font-size: 64px;
      line-height: 72px;
    }

    .hero-copy {
      margin-top: 20px;
      line-height: 36px;
    }

    .hero-text {
      margin-top: 20px;
      max-width: 800px;
    }

    .hero-call-to-action {
      border: solid ${colorBackground} 1px;
      margin-top: auto;
      margin-right: auto;
      font-weight: 700;

      &:hover:not(:disabled),
      &:focus:not(:disabled),
      &:active:not(:disabled) {
        border-color: ${colorBackgroundSecondary};
      }
    }
  `;
};

function Hero({
  className, classes,
}) {
  return (
    <div className={className}>
      <h1>
        Welcome to
        {' '}
        <span className={classes.brand}>Cosmic Puma</span>
      </h1>
      <h1 className={classes.copy}>Read, write and share stories...</h1>
      <h3 className={classes.text}>
        Join a community dedicated to a long sentence that tests the boundaries of how this grid is going to work.
      </h3>
      <Button
        size="large"
        color="secondary"
        variant="solid"
        className={classes.cta}
      >
        Get Started!
      </Button>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    overlay: PropTypes.string,
    welcome: PropTypes.string,
    brand: PropTypes.string,
    copy: PropTypes.string,
    text: PropTypes.string,
    cta: PropTypes.string,
  }),
};

Hero.defaultProps = {
  classes: {
    overlay: 'hero-overlay',
    welcome: 'hero-welcome',
    brand: 'hero-brand',
    copy: 'hero-copy',
    text: 'hero-text',
    cta: 'hero-call-to-action',
  },
};

const StyledHero = styled(Hero)`${styles}`;

StyledHero.defaultProps = {
  ...Hero.defaultProps,
};

export default withTheme(StyledHero);
