import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from '../Auth';
import ArticlesClient from '../../../services/ArticlesClient';

export const RestClientContext = createContext();
export const RestClientProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const articlesClient = new ArticlesClient({
    url: process.env.GATSBY_ARTICLES_HOST,
    authProvider: (headers) => {
      if (user) {
        return { ...headers, authorization: `Bearer ${user.accessToken}` };
      }

      return headers;
    },
  });

  return (
    <RestClientContext.Provider
      value={{
        articlesClient,
      }}
    >
      {children}
    </RestClientContext.Provider>
  );
};

RestClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
