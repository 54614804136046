const getTags = () => [
  { value: 'firsttime', label: 'First Time' },
  { value: 'bdsm', label: 'BDSM' },
  { value: 'college', label: 'College' },
  { value: 'hottubs', label: 'Hot Tubs' },
  { value: 'travel', label: 'Travel' },
  { value: 'workplace', label: 'Work Place' },
  { value: 'feet', label: 'Feet' },
];

export default getTags;
