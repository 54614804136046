const ApiClient = require('./ApiClient');

/**
 * HttpClient for the Articles API.
 */
class ArticlesClient extends ApiClient {
  constructor({ url = 'https://api-test.cosmicpuma.com', authProvider }) {
    super({ url, authProvider });
  }

  /**
   * Create a new article.
   * @param {object} articleDetails - the request details
   * @param {string} articleDetails.userId - the id of the user
   * @param {string} accessToken - the accessToken for authentication with the backend api
   * @returns {Article}
   */
  createArticleShell({
    userId,
  }) {
    return super.request('/article/articles/v1', {
      method: 'post',
      data: {
        userId,
        title: '',
        description: '',
        category: '',
      },
    })
      .then(({ data }) => data);
  }

  /**
   * Update an existing article's text with a new value.
   * @param {object} article - the article data
   * @param {string} article.id - the full id of the article
   * @param {string} article.userId - the id of the user owning the draft
   * @param {string} article.title - the title of the article
   * @param {string} article.description - the description of the article
   * @param {string} article.category - the category of the article
   * @param {string} article.tags - the tags for the article
   * @param {string} article.status - the publish status of the article
   */
  updateArticle({
    id, userId, title, description, category, tags, status,
  }) {
    return super.request(`/article/articles/v1/${id}`, {
      method: 'put',
      data: {
        userId,
        title,
        description,
        category,
        tags,
        status,
      },
    })
      .then(({ data }) => data);
  }

  saveArticle({
    id, userId, title, description, category, tags,
  }, options) {
    return this.updateArticle({
      id,
      userId,
      title,
      description,
      category,
      tags,
      status: 'DRAFT',
    }, options);
  }

  publishArticle({
    id, userId, title, description, category, tags,
  }) {
    return this.updateArticle({
      id,
      userId,
      title,
      description,
      category,
      tags,
      status: 'PUBLISHED',
    });
  }

  /**
   * Get an article by its id.
   * @param {string} id - the article id
   */
  getArticleById(id) {
    return super.request(`/article/articles/v1/${id}`)
      .then(({ data }) => data);
  }

  /**
   * Get all articles by a user.
   * @param {string} userId - the user id
   */
  getArticlesByUserId(userId) {
    return super.request(`/article/articles/v1?userId=${userId}`)
      .then(({ data: { objects: articles } }) => articles);
  }

  /**
   * Get all articles by a user.
   * @param {string} username - the user id
   */
  getArticlesByUsername(username) {
    return super.request(`/article/articles/v1?username=${username}`)
      .then(({ data: { objects: articles } }) => articles);
  }

  /**
   * Get article by user and cuid.
   * @param {string} username - the user id
   * @param {string} cuid - the article cuid
   */
  async getArticleByUsernameAndCuid(username, cuid) {
    const article = await super.request(`/article/articles/v1?username=${username}&cuid=${cuid}`)
      .then(({ data: { objects: [article] } }) => article);
    const text = await this.getArticleTextByPresignedUrl(article.blobUri);
    const articleWithText = { ...article, text };
    return articleWithText;
  }

  getArticleTextByPresignedUrl(presignedUrl) {
    return super.get(presignedUrl)
      .then(({ data }) => data);
  }

  /**
   * Unpublish the article with the given id. It will return to DRAFT status.
   * @param {string} articleId - the full id of the article
   * @param {object} documentData - the document json
   */
  updateArticleTextById(articleId, documentData) {
    return super.request('/article/update_article_text/v1', {
      method: 'put',
      params: {
        articleId,
      },
      data: documentData,
    })
      .then(({ data }) => data);
  }
}

module.exports = ArticlesClient;
