import React from 'react';
import PropTypes from 'prop-types';

function ProfileArticleCard(props) {
  const {
    className,
    value,
    message,
    ...rest
  } = props;

  return (
    <div className={className} {...rest}>
      <h1>{value}</h1>
      <p>{message}</p>
    </div>
  );
}

ProfileArticleCard.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  message: PropTypes.string.isRequired,
};

ProfileArticleCard.defaultProps = {
  className: undefined,
};

export default ProfileArticleCard;
