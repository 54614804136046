const getTrendingArticles = async ({ articlesClient }) => {
  const confirmedUserIds = [
    '7881410a-24a3-41a8-a787-ca3476333983',
    'af62d3f3-28d0-46e6-b163-f1eee1d676f5', // spacekitty
    '1b40bd6f-9215-4028-aae2-62b0c790a7d7',
    '75f12c76-6204-464d-a5e8-05a7ac28aa8a',
    '804eed98-0b50-4f4f-8273-cec1bafed99f',
    'e1db0c1b-a7d3-4bf9-912f-00135bb207b5', // cosmicpuma
  ];

  let trendingArticles;

  try {
    const articlesByUserId = await Promise.all(confirmedUserIds.map(
      (userId) => articlesClient.getArticlesByUserId(userId)
        .then((articles) => articles.filter((article) => article.status === 'PUBLISHED')),
    ));

    const articles = articlesByUserId.flat();

    trendingArticles = articles;
  } catch (err) {
    console.error(err);
  }

  return trendingArticles;
};

export default getTrendingArticles;
