import { css } from '@emotion/react';

const styles = (props) => {
  const {
    theme: {
      spacingL,
      fontSizeL,
      fontFamilyBrand,
      colorText,
      colorBackground,
    },
  } = props;

  return css`
    background: ${colorBackground};

    .appbar-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    .appbar-brand {
      margin-left: ${spacingL};
      margin-right: auto;
      font-family: ${fontFamilyBrand};
      font-size: ${fontSizeL};
      color: ${colorText};
    }

    .appbar-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${spacingL};
    }

    .appbar-actions {
      margin-left: ${spacingL};
    }
  `;
};

export default styles;
