/**
 * @typedef {import("./CosmicPumaUser").default} CosmicPumaUser
 */

/**
 * AuthContext type definition
 * @typedef TAppAuthContext auth context
 * @property {function} signIn function used to sign in a user
 * @property {function} signUp function used to register new users
 * @property {function} signOut function used to sign out the current user
 * @property {CosmicPumaUser} user the current app user
 * @property {boolean} isSignedIn true if someone is signed in
 * @property {function} confirmSignUp function used to confirm new account verification codes
 * @property {function} resendSignup function used to send new verification codes for new accounts
 */

import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import { useAuth, defaultAuthParams } from '../../../utils/hooks';

/**
 * @type {import('react').Context<TAppAuthContext>}
 */
export const AuthContext = createContext();
// TODO: maybe this context should not include `...rest`; don't want any auth surprises
export function AuthProvider({ children, ...rest }) {
  // TODO: verify provider, might be here: https://aws-amplify.github.io/amplify-js/api/enums/cognitohosteduiidentityprovider.html
  const {
    signIn,
    signUp,
    signOut,
    user,
    isSignedIn,
    confirmSignUp,
    resendSignup,
  } = useAuth(defaultAuthParams);

  return (
    <AuthContext.Provider
      // todo: define the Provider value as a Class with TS Documentation
      /**
       * @type {import('react').Context<TAuthContext>}
       */
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        signIn,
        signUp,
        signOut,
        user,
        isSignedIn,
        confirmSignUp,
        resendSignup,
        ...rest,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
