import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { AuthContext } from '../contexts/Auth';
import AppBar from '../AppBar';

function Layout(props) {
  const {
    children,
  } = props;

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  const { isSignedIn, signOut, user } = useContext(AuthContext);

  return (
    <div>
      <AppBar
        siteTitle={data.site.siteMetadata.title}
        isSignedIn={isSignedIn}
        signOut={signOut}
        user={user}
      />
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
