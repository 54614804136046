import { css } from '@emotion/react';

const styles = (props) => {
  const {
    theme: {
      spacingL,
      colorBackground,
    },
  } = props;

  const backgroundLighter = '#383838';

  return css`
    background: ${colorBackground};
    padding: ${spacingL};
    border-radius: 5px;
    border: 1px solid ${backgroundLighter};
    box-shadow: hsla(0, 0, 0, .2) 0 4px 2px -2px;
  `;
};

export default styles;
