import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';

import Unstyled from './VerifyAccount';
import styles from './styles';

const VerifyAccount = styled(Unstyled)`
  ${styles}
`;

VerifyAccount.defaultProps = {
  ...Unstyled.defaultProps,
};

export default withTheme(VerifyAccount);
