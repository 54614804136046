import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from '../contexts/Auth';
import AppAuthMode from '../AppAuthMode';

/**
 * React component for rendering an Account Verification form
 *
 * @param {function} changeAuthMode callback to update the app auth mode,
 *                   must accept AppAuthState enum
 * @param {string} verifyEmail the email of the account being verified
 * @returns React component
 */
function VerifyAccount({ changeAuthMode, verifyEmail }) {
  const { confirmSignUp, resendSignup } = useContext(AuthContext);
  const [verificationCode, setVerificationCode] = useState('');

  const handleVerificationError = (error) => {
    switch (error.code) {
      case 'ExpiredCodeException':
        resendSignup(verifyEmail);
        break;
      case 'CodeMismatchException':
        console.error('Provided verification code is incorrect!');
        setVerificationCode('');
        break;
      default:
        console.error(`Unhandled verification errorCode=${error.code}`);
        break;
    }
  };

  const handleVerifyClicked = (event) => {
    event.preventDefault();
    console.log('verifying account');
    return confirmSignUp(verifyEmail, verificationCode)
      .then(() => changeAuthMode(AppAuthMode.SIGN_IN))
      .catch(handleVerificationError);
  };

  const handleVerificationCodeChanged = (event) => {
    const verificationCode = event.target.value;
    setVerificationCode(verificationCode);
  };

  return (
    <div label="VerifyAccount" className="verification-form">
      <form>
        Check your email for a recent Verification Code!
        <label htmlFor="code">
          <p>verification code</p>
          <input
            value={verificationCode}
            onChange={handleVerificationCodeChanged}
            type="text"
            className="verification-code-input"
            id="code"
          />
        </label>
        <div>
          <button type="submit" onClick={handleVerifyClicked}>
            Verify
          </button>
        </div>
      </form>
    </div>
  );
}

VerifyAccount.propTypes = {
  changeAuthMode: PropTypes.func,
  verifyEmail: PropTypes.string,
};

VerifyAccount.defaultProps = {
  changeAuthMode: undefined,
  verifyEmail: undefined,
};

export default VerifyAccount;
