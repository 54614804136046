/**
 * This class defines an interface for access to attributes of a site User.
 * The abstraction is useful to decouple the definition of a user in our
 * current auth tool from the concepts we want in our application, so that
 * we can freely change our auth tool or structure in the future without
 * impacting the rest of the application.
 */
class CosmicPumaUser {
  /**
     * Create a new CosmicPumaUser object.
     * @constructor
     * @param {object} props
     * @param {string} props.email the email of the user
     * @param {string} props.username the username in the system
     * @param {string} props.userId the id in the system
     * @param {string} props.accessToken the jwt access token for authenticated
     *                 requests in our application as this user
     */
  constructor({
    email, username, userId, accessToken,
  }) {
    this.email = email;
    this.username = username;
    this.userId = userId;
    this.accessToken = accessToken;
  }
}

export default CosmicPumaUser;
