import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';

import Unstyled from './SignedIn';
import styles from './styles';

const SignedIn = styled(Unstyled)`
  ${styles}
`;

SignedIn.defaultProps = {
  ...Unstyled.defaultProps,
};

export default withTheme(SignedIn);
