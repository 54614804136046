import { css } from '@emotion/react';

const styles = (props) => {
  const {
    theme: {
      spacingL,
    },
  } = props;

  return css`
    margin-top: ${spacingL};
  `;
};

export default styles;
