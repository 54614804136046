import { css } from '@emotion/react';

const styles = (props) => {
  const {
    theme: {
      spacingM,
    },
  } = props;

  return css`
    .profile-stat-line {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: ${spacingM};

      & > div {
        flex-grow: 1;
      }

      & > div:not(:last-child) {
        margin-right: ${spacingM};
      }
    }

    .profile-posts-header-line {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .profile-sortby-dropdown {
      margin-left: auto;
      max-width: 30%;
    }

    .profile-card {
      margin-bottom: ${spacingM};
    }

    h3 {
      font-weight: 700;
    }
  `;
};

export default styles;
