import React from 'react';
import PropTypes from 'prop-types';
import { css, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import RateArticle from '../../components/RateArticle';

const styles = () => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .read-rate-article {

  }
`;

function LeftBar(props) {
  const {
    className,
    classes,
    isSignedIn,
    isLoadingRating,
    crupdateRating,
    articleBaseRating,
    userRating,
  } = props;

  const rateArticleProps = {
    crupdateRating,
    articleBaseRating,
    userRating,
  };

  return (
    <Container>
      <div className={className}>
        {isLoadingRating && (
        <div>
          <CircularProgress />
        </div>
        )}
        {!isLoadingRating && (
          <RateArticle
            className={classes.rateArticle}
            disabled={!isSignedIn}
            {...rateArticleProps}
          />
        )}
      </div>
    </Container>
  );
}

LeftBar.propTypes = {
  className: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    rateArticle: PropTypes.string,
  }),
  isSignedIn: PropTypes.bool.isRequired,
  isLoadingRating: PropTypes.bool.isRequired,
  crupdateRating: PropTypes.func.isRequired,
  articleBaseRating: PropTypes.number.isRequired,
  userRating: PropTypes.number.isRequired,
};

LeftBar.defaultProps = {
  classes: {
    rateArticle: 'read-rate-article',
  },
};

const StyledLeftBar = styled(LeftBar)`${styles}`;

StyledLeftBar.defaultProps = {
  ...LeftBar.defaultProps,
};

export default withTheme(StyledLeftBar);
