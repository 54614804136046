import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';

import Unstyled from './PublishModal';
import styles from './styles';

const PublishModal = styled(Unstyled)`
  ${styles}
`;

PublishModal.defaultProps = {
  ...Unstyled.defaultProps,
};

export default withTheme(PublishModal);
