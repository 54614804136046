import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { useTheme } from '@emotion/react';
// import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { cosmicpuma } from '@nonsequitur/component-library';

import Layout from '../../components/Layout';
// import ProfileArticleCard from '../../components/ProfileArticleCard';
import StatCard from '../../components/ProfileStatCard';
// import { useMediaQuery } from '../../utils/hooks';

const { DropDown } = cosmicpuma;

function ProfileTemplate(props) {
  const {
    className,
    classes,
    pageContext,
  } = props;

  const {
    name, type, description, copy,
  } = pageContext;

  const [sortBy, setSortBy] = useState();
  // const { breakpointS } = useTheme();
  // const isSmallScreen = useMediaQuery(`(max-width: ${breakpointS})`);

  const handleOnChangeSortBy = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setSortBy(value);
  };

  const sortByOptions = [
    { value: 'created', label: 'Recently Created' },
    { value: 'published', label: 'Recently Published' },
    { value: 'views', label: 'Most Views' },
    { value: 'ratings', label: 'Highest Rating' },
  ];

  const statCardData = [
    { value: 300, message: 'Total post views' },
    { value: 0, message: 'Total likes' },
    { value: 10, message: 'Total posts' },
  ];

  return (
    <div className={className}>
      <h1>
        #
        {name}
      </h1>
      <p>{type}</p>
      <p>{description}</p>
      <p>{copy}</p>
      <div className={classes.statLine}>
        {statCardData.map((stat) => (
          <StatCard
            value={stat.value}
            message={stat.message}
            key={`key-${stat.value}`}
          />
        ))}
      </div>
      <div className={classes.postsHeaderLine}>
        <h3>Posts</h3>
        <DropDown
          id="profile-article-sort"
          name="profile-article-sort"
          label="Sort"
          placeholder="Sort by..."
          value={sortBy}
          onChange={handleOnChangeSortBy}
          options={sortByOptions}
          className={classes.sortBy}
        />
      </div>
    </div>
  );
}

ProfileTemplate.propTypes = {
  classes: PropTypes.shape({
    statLine: PropTypes.string,
    postsHeaderLine: PropTypes.string,
    sortBy: PropTypes.string,
    card: PropTypes.string,
  }),
  className: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    copy: PropTypes.string,
  }).isRequired,
};

ProfileTemplate.defaultProps = {
  classes: {
    statLine: 'profile-stat-line',
    postsHeaderLine: 'profile-posts-header-line',
    sortBy: 'profile-sortby-dropdown',
    card: 'profile-card',
  },
};

function Wrapper(props) {
  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={false} sm={1} md={3} />
        <Grid item xs={12} sm={10} md={6}>
          <ProfileTemplate {...props} />
        </Grid>
        <Grid item xs={false} sm={1} md={6} />
      </Grid>
    </Layout>
  );
}

export default Wrapper;
